<template>
  <div class="echarts">
    <div class="echarts-item" v-for="(item, idx) in boardData" :key="idx">
        <div class="echarts-item-box">
            <chart-board :boardData="item"></chart-board>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      boardData: {
        default: function () {
          return []
        },
        type: Array
      }
    },
    data () {
      return {
        // winChips: new Array(11).fill(0)
      }
    },
    watch: {
// 　　 winChips: {
// 　　　　handler(newValue, oldValue) {
// 　　　　　　for (let i = 0; i < newValue.length; i++) {
// 　　　　　　　　if (oldValue[i] != newValue[i]) {
// 　　　　　　　　　　console.log(newValue)
// 　　　　　　　　}
// 　　　　　　}
// 　　　　},
// 　　　　deep: true
// 　　  }
    },
    // watch: {
    //   "boardData":{
    //     handler: function(newVal){
    //       console.log('newVal： ' + newVal)
    //     },
    //     deep: true
    //   }
    // },
    methods: {

    },
    mounted () {
      console.log('boardData: ' + JSON.stringify(this.boardData))
    }
  }
</script>

<style lang='sass' scoped>
.echarts
  &-item
    display: inline-block
    position: relative
    width: 100%
    height: 241px
    vertical-align: top
    &-box
      height: 100%
      width: 500px
      margin: 0 auto
      max-width: 500px
</style>
